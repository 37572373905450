class PlanejamentoNutricional {
    constructor() {
        this.tmb = "";
        this.eer = "";
        this.fa = "";
        this.metSemanal = "";
        this.planoAlimentar = "";
        this.tipoPlanoPrescrito = "";
        this.meta = "";
    }
}

export default PlanejamentoNutricional;