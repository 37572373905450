class Medicamentos {
    constructor() {
        this.medicamento1 = {
            nomeMedicamento1: "",
            horarioConsumoMedicamento1: "",
            dosagemMedicamento1: "",
            frequenciaMedicamento1: "",
        };
        this.medicamento2 = {
            nomeMedicamento2: "",
            horarioConsumoMedicamento2: "",
            dosagemMedicamento2: "",
            frequenciaMedicamento2: "",
        };
        this.medicamento3 = {
            nomeMedicamento3: "",
            horarioConsumoMedicamento3: "",
            dosagemMedicamento3: "",
            frequenciaMedicamento3: "",
        };
        this.medicamento4 = {
            nomeMedicamento4: "",
            horarioConsumoMedicamento4: "",
            dosagemMedicamento4: "",
            frequenciaMedicamento4: "",
        };
        this.medicamento5 = {
            nomeMedicamento5: "",
            horarioConsumoMedicamento5: "",
            dosagemMedicamento5: "",
            frequenciaMedicamento5: "",
        };
    }
}

export default Medicamentos;