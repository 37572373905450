class ExamesClinicos {
    constructor() {
        this.mucosa = "";
        this.habitosUrinarios = "";
        this.edema = "";
        this.funcaoIntestinal = "";
        this.pa = "";
        this.fc = "";
        this.bristol = "";
        this.frequenciaEvacuacao = "";
    }
}

export default ExamesClinicos;