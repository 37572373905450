class ExamesBioquimicos {
    constructor() {
        this.data = "";
        this.hematocrito = "";
        this.ct = "";
        this.ureia = "";
        this.hemacia = "";
        this.ldl = "";
        this.tgo = "";
        this.hemoglobina = "";
        this.hdl = "";
        this.tgp = "";
        this.glicemiaJejum = "";
        this.vldl = "";
        this.tsh = "";
        this.a1c = "";
        this.creatina = "";
        this.t4 = "";
    }
}

export default ExamesBioquimicos;